<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              Are you sure you want to delete this user?
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          outlined
          @click="closeModal"
        >
          Close
        </v-btn>
        <v-btn
          color="error"
          @click="deleteUser"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getCurrentInstance, ref } from '@vue/composition-api'
import gql from 'graphql-tag'

export default {
  name: 'DeleteDialog',
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    admin: {
      type: Object,
      required: true,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props, { emit, root }) {
    const isDialogVisible = ref(props.show)
    const vm = getCurrentInstance().proxy

    const closeModal = () => {
      isDialogVisible.value = false
      emit('update:admin', null)
      emit('update:show', false)
    }

    const deleteUser = async () => {
      try {
        await root.$apollo.mutate({
          mutation: gql`
            mutation ($id: ID!) {
              deleteUser(id: $id) {
                id
                email
                role
                organization {
                  id
                  name
                  color
                }
              }
            }
          `,
          variables: {
            id: props.admin.id,
          },
        })
        emit('success')
        vm.$toastr.s('User successfully deleted!')
      } catch (err) {
        vm.$toastr.e(err.networkError ? 'Network Error' : err.graphQLErrors)
      } finally {
        emit('update:admin', null)
        emit('update:show', false)
        isDialogVisible.value = false
      }
    }

    return {
      isDialogVisible,
      closeModal,
      deleteUser,
    }
  },
}
</script>
